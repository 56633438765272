import React from 'react';
import Typography from '@mui/material/Typography';
import letterSrc from './letter.svg';
import NoticePageLayout from '../NoticePageLayout';
import { MoSpacing } from '../MoSpacing';

type NoticePageLayoutProps = {
  title?: React.ReactNode;
  body?: React.ReactNode;
};

export default function EmailVerificationPage({
  title = 'Email changed',
  body,
}: NoticePageLayoutProps) {
  return (
    <NoticePageLayout
      title={title}
      body={
        body || (
          <MoSpacing y={2}>
            <Typography>
              Your email has been changed. Please check the inbox of your new email to reset your
              password and reactivate your account.
            </Typography>
          </MoSpacing>
        )
      }
      imageSrc={letterSrc}
    />
  );
}
