import { ApolloClient, ApolloLink, from, InMemoryCache } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import fetchWithAuthHeaders from '@motivo/styx/src/fetchWithAuthHeaders';
import { addBreadcrumb, SeverityLevel } from '@sentry/react';
import { generatePersistedQueryIdsFromManifest } from '@apollo/persisted-query-lists';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { onError } from '@apollo/client/link/error';
import { logout, clearAuthState } from '@motivo/styx/src/auth';

const httpLink = createUploadLink({
  uri: `${import.meta.env.VITE_API_URL}/graphql`,
  // @ts-ignore
  fetch: fetchWithAuthHeaders,
  headers: {
    'Apollo-Require-Preflight': 'true',
  },
});

const errorLink = onError(({ graphQLErrors }) => {
  if (window.location.pathname === '/email-verification') {
    return;
  }

  if (graphQLErrors?.some((error) => error.message?.includes('User not found for WorkOS token'))) {
    clearAuthState();
    window.location.reload();
  }

  if (graphQLErrors?.some((error) => error.message?.includes('Invalid JWT'))) {
    logout();
  }
});

const breadcrumbLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    const { errors } = response;
    if (errors) {
      addBreadcrumb({
        type: 'error',
        category: 'graphql',
        timestamp: Date.now() / 1000,
        level: 'error' as SeverityLevel,
        message: operation.operationName,
        data: {
          error: errors[0].message,
        },
      });
    } else {
      addBreadcrumb({
        type: 'http',
        category: 'graphql',
        timestamp: Date.now() / 1000,
        data: {
          url: operation.operationName,
        },
      });
    }

    return response;
  });
});

const PERSISTED_QUERIES_MANIFEST_NAME = 'persisted-query-manifest';

const persistedQueryLink =
  import.meta.env.MODE === 'production'
    ? createPersistedQueryLink(
        generatePersistedQueryIdsFromManifest({
          loadManifest: () =>
            import(
              /* @vite-ignore */
              `../../${PERSISTED_QUERIES_MANIFEST_NAME}.json`
            ),
        }),
      )
    : new ApolloLink((operation, forward) => forward(operation));

export default new ApolloClient({
  link: from([persistedQueryLink, breadcrumbLink, errorLink, httpLink]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          chatsWithUnreadMessages: {
            // Always overwrite the pre-existing list
            merge(_, incoming) {
              return incoming;
            },
          },
          chatMessages: {
            keyArgs: ['chatId'],
            // @ts-expect-error TS(2339) FIXME: Property 'cursor' does not exist on type 'Record<s... Remove this comment to see the full error message
            merge(existing, incoming, { args: { cursor } }) {
              if (!cursor || !existing) {
                return incoming;
              }
              return {
                __typename: 'ChatMessagesResult',
                result: [...incoming.result, ...existing.result],
                nextCursor: incoming.nextCursor,
              };
            },
          },
          viewer: {
            merge(existing, incoming, { mergeObjects }) {
              if (incoming?.id && existing?.id && existing.id === incoming.id) {
                return mergeObjects(existing, incoming);
              }

              return incoming;
            },
          },
        },
      },
    },
  }),
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache',
    },
  },
});
