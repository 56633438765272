import { useQuery } from '@apollo/client';
import { EmptyOrLoadingState } from '@motivo/guanyin/src/components';
import authRoutes from '@motivo/styx/src/authRoutes';
import useSetUpAnalytics from '@motivo/styx/src/useSetUpAnalytics';
import { StyledEngineProvider } from '@mui/material/styles';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { Suspense, lazy, useEffect } from 'react';
import { Route as ReactRouterRoute, RouteProps, Switch } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import EmailVerificationPage from '@motivo/guanyin/src/components/EmailVerificationPage';
import { ThemeProvider } from './ThemeProvider';
import { ViewerQueryQuery } from './__generated__/graphql';
import Layout from './components/Layout';
import SettingsLayout from './components/SettingsLayout';
import { editProgramSuperviseesPath } from './domains/programs/EditSuperviseesPage';
import { SelectedProgramFilterProvider } from './domains/programs/SelectedProgramFilterContext';
import { programSettingsPagePath } from './domains/settings/ProgramSettingsPage';
import { viewerQuery } from './hooks/useViewer';
import SnackbarState from './hooks/SnackbarState';

const Homepage = lazy(() => import('./pages/Homepage'));
const CheckAuth = lazy(() => import('./components/CheckAuth'));
const SessionsPage = lazy(() => import('./domains/sessions'));
const SupervisorsPage = lazy(() => import('./domains/supervisors/SupervisorsPage'));
const SuperviseesPage = lazy(() => import('./domains/supervisees/SuperviseesPage'));
const ProgramsPage = lazy(() => import('./domains/programs/ProgramsPage'));
const EditProgramSuperviseesPage = lazy(() => import('./domains/programs/EditSuperviseesPage'));
const ProgramSettingsPage = lazy(() => import('./domains/settings/ProgramSettingsPage'));

const PrepForCallPage = lazy(() => import('./domains/onboarding/PrepForCallPage'));
const ScheduleCallPage = lazy(() => import('./domains/onboarding/ScheduleCallPage'));
const MyIntroRequestsPage = lazy(() => import('./domains/introRequests/IntroRequestsPage'));
const ScheduleCallSuccessPage = lazy(() => import('./domains/onboarding/ScheduleCallSuccessPage'));
const IntroRequestDetailsPage = lazy(
  () => import('./domains/introRequests/IntroRequestDetailsPage'),
);
const BookIntroCallPage = lazy(() => import('./domains/introRequests/BookIntroCallPage'));
const SuperviseeCSVUploadPage = lazy(() => import('./domains/supervisees/SuperviseeCSVUploadPage'));
const SuperviseeManualUploadPage = lazy(
  () => import('./domains/supervisees/SuperviseeManualUploadPage'),
);

const AdminHoursPage = lazy(() => import('./domains/admin-hours/AdminHoursPage'));
const GeneralSettingsPage = lazy(() => import('./domains/settings/GeneralPage'));
const ChatsPage = lazy(() => import('./domains/messages/ChatsPage'));
const TeamPage = lazy(() => import('./domains/settings/TeamPage'));
const ManageNotificationsPage = lazy(() => import('./domains/settings/ManageNotificationsPage'));
const BillingPage = lazy(() => import('./domains/settings/BillingPage'));
const ResetPasswordPage = lazy(() => import('./domains/settings/ResetPasswordPage'));

interface RoutePropsWithTitle extends RouteProps {
  title?: string;
}

function Route<T extends RoutePropsWithTitle>({ children, title, ...rest }: T) {
  useEffect(() => {
    if (title) {
      document.title = `${title} | Motivo for Employers`;
    }
  }, [title]);

  return <ReactRouterRoute {...rest}>{children}</ReactRouterRoute>;
}

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY, {
  stripeAccount: import.meta.env.VITE_STRIPE_ACCOUNT_ID,
});

const App = () => {
  // Preload the viewer so it can be assumed to be available everywhere.
  const { data, loading, refetch } = useQuery(viewerQuery);
  const viewer = data?.viewer;

  useSetUpAnalytics<ViewerQueryQuery['viewer']>({
    user: viewer,
    afterSetUser: ({ posthog, user }) => {
      // @ts-expect-error TS(2533) FIXME: Object is possibly 'null' or 'undefined'.
      posthog.group('organization', user.organizationId.toString(), {
        // @ts-expect-error TS(2533) FIXME: Object is possibly 'null' or 'undefined'.
        name: user.organization.name,
      });
    },
    getAdditionalProperties: (user) => ({
      organizationRoleId: user.organizationRoleId,
    }),
  });

  if (loading) {
    return <EmptyOrLoadingState loading />;
  }

  return (
    <SnackbarProvider>
      <SnackbarState.Provider>
        <StyledEngineProvider injectFirst>
          <ThemeProvider>
            {/* @ts-ignore */}
            <Elements stripe={stripePromise}>
              <Suspense fallback={<EmptyOrLoadingState loading />}>
                <Switch>
                  <Route path="/reset-password" exact>
                    <ResetPasswordPage />
                  </Route>
                  <Route path="/email-verification" exact>
                    <EmailVerificationPage />
                  </Route>
                  {authRoutes({ fetchViewer: refetch })}
                  <CheckAuth>
                    <SelectedProgramFilterProvider>
                      <Layout>
                        <Suspense fallback={<EmptyOrLoadingState loading />}>
                          <Switch>
                            <Route exact path="/">
                              <Homepage />
                            </Route>
                            <Route path="/sessions" exact>
                              <SessionsPage />
                            </Route>
                            <Route path="/supervisees" exact>
                              <SuperviseesPage />
                            </Route>
                            <Route path="/supervisees/upload" exact>
                              <SuperviseeCSVUploadPage />
                            </Route>
                            <Route path="/supervisees/upload-form" exact>
                              <SuperviseeManualUploadPage />
                            </Route>
                            <Route path="/supervisors" exact>
                              <SupervisorsPage />
                            </Route>
                            <Route path="/programs" exact>
                              <ProgramsPage />
                            </Route>
                            <Route path={programSettingsPagePath(':programId')} exact>
                              <ProgramSettingsPage />
                            </Route>
                            <Route path={editProgramSuperviseesPath(':id')} exact>
                              <EditProgramSuperviseesPage />
                            </Route>
                            <Route
                              path="/employer-onboarding/prep-for-call/:introRequestOrganizationInfoId?"
                              exact
                            >
                              <PrepForCallPage />
                            </Route>
                            <Route path="/employer-onboarding/schedule-call/success" exact>
                              <ScheduleCallSuccessPage />
                            </Route>
                            <Route
                              path="/employer-onboarding/schedule-call/:introRequestOrganizationInfoId"
                              exact
                            >
                              <ScheduleCallPage />
                            </Route>
                            <Route path="/intro-calls" exact>
                              <MyIntroRequestsPage />
                            </Route>
                            <Route path="/intro-calls/:id" exact>
                              <IntroRequestDetailsPage />
                            </Route>
                            <Route path="/intro-calls/create/:opportunitiesCandidateId" exact>
                              <BookIntroCallPage />
                            </Route>
                            <Route path="/chats/:id?" exact>
                              <ChatsPage />
                            </Route>
                            <Route path="/admin-hours" exact>
                              <AdminHoursPage />
                            </Route>
                            <SettingsLayout>
                              <Suspense fallback={<EmptyOrLoadingState loading />}>
                                <Switch>
                                  <Route path="/settings" exact>
                                    <GeneralSettingsPage />
                                  </Route>
                                  <Route path="/settings/billing" exact>
                                    <BillingPage />
                                  </Route>
                                  <Route path="/settings/notifications" exact>
                                    <ManageNotificationsPage />
                                  </Route>
                                  <Route path="/settings/team" exact>
                                    <TeamPage />
                                  </Route>
                                </Switch>
                              </Suspense>
                            </SettingsLayout>
                          </Switch>
                        </Suspense>
                      </Layout>
                    </SelectedProgramFilterProvider>
                  </CheckAuth>
                </Switch>
              </Suspense>
            </Elements>
          </ThemeProvider>
        </StyledEngineProvider>
      </SnackbarState.Provider>
    </SnackbarProvider>
  );
};

export default App;
